import { ArrowLeft, BarChart, Home, TrendinigUp } from "@hexa-ui/icons";
import { useMemo } from "react";
import { routes } from "router/constants";
import { ESidebarVisibility, ISidebarItem } from "types/Sidebar.types";

interface IUseSidebarItem {
	sidebarLinks: ISidebarItem[];
}

export const useMenuUtils = () => {
	const sidebarUtilsLinks = useMemo<ISidebarItem[]>(
		() => [
			{
				id: "6",
				title: "Back to BEES One",
				icon: () => <ArrowLeft />,
				path: routes.BEES_ONE,
				visible: ESidebarVisibility.AllUsers,
				target: "_self"
			}
		],
		[]
	);

	return { sidebarUtilsLinks };
};

export const useMenuItems = (): IUseSidebarItem => {
	const sidebarLinks = useMemo<ISidebarItem[]>(
		() => [
			{
				id: "1",
				title: "Home",
				icon: () => <Home />,
				path: routes.HOME_PAGE,
				visible: ESidebarVisibility.AllUsers
			},
			{
				id: "2",
				title: "Global Reports",
				icon: () => <BarChart />,
				path: routes.GLOBAL_REPORTS.BASE_GLOBAL_REPORTS,
				visible: ESidebarVisibility.BeesDataPortalUsers
			},
			// {
			// 	id: "3",
			// 	title: "Infra Services",
			// 	icon: () => <Settings />,
			// 	path: routes.INFRA_SERVICES.INFRA_SERVICES,
			// 	visible: true
			// },
			{
				id: "4",
				title: "Usage Metrics",
				icon: () => <TrendinigUp />,
				path: routes.USAGE_METRICS,
				visible: ESidebarVisibility.BeesUsers
			}
			// {
			// 	id: "5",
			// 	title: "Self Service BI",
			// 	icon: () => <BarChart3 />,
			// 	path: routes.SELF_SERVICE_BI.SELF_SERVICE_BI,
			// 	visible: ESidebarVisibility.BeesUsers
			// },
			// {
			// 	id: "6",
			// 	title: "Access Management",
			// 	icon: () => <Lock size="large" />,
			// 	path: routes.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT,
			// 	visible: true
			// },
			// {
			// 	id: "7",
			// 	title: "Data Catalog",
			// 	icon: () => <BookOpen />,
			// 	path: routes.DATA_CATALOG,
			// 	visible: false
			// },
			// {
			// 	id: "8",
			// 	title: "Data Governance",
			// 	icon: () => <Shield />,
			// 	path: routes.DATA_GOVERNANCE,
			// 	visible: false
			// },
			// {
			// 	id: "9",
			// 	title: "Digital Comms Services",
			// 	icon: () => <MessageCircle />,
			// 	path: routes.DIGITAL_COMMUNICATION,
			// 	visible: false
			// }
		],
		[]
	);

	return { sidebarLinks };
};
