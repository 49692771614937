import { Grid, Heading, Paragraph } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";
import { DashboardCard } from "components/Cards/DashboardCard/DashboardCard";
import { DomainBanner, IDomainBannerProps } from "components/Cards/DomainBanner/DomainBanner";
import { ReportErrorState } from "components/EmptyStates/ReportErrorState";
import { ReportNoContentState } from "components/EmptyStates/ReportNoContentState";
import Loader from "components/shared/Loader/Loader";
import { useLoaderContext, useReportsContext } from "contexts";
import { tabGlobalReportSelectedTracking } from "pages/GlobalReportsPage/globalReportsPageTracking";
import { useState } from "react";
import { useIntl } from "react-intl";
import { IPagination, useGetReportsByDomain } from "services/Report/useGetReportsByDomain";
import { WrapperStyled } from "styles/shared/WrapperStyled/WrapperStyled";
import { IDashboardCard } from "types/Dashboard.types";
import { EBeesDomains } from "types/Domain.types";
import { IReport } from "types/Report.types";
import { showNotify } from "utils/notify";

const { Container } = Grid;
const PAGE_SIZE = 100;

interface IReportsTabContentProps {
	domainName: EBeesDomains;
	domainId: number;
	domainBanner?: IDomainBannerProps;
	size?: number;
	offset?: number;
}

const ReportsTabContent = ({
	domainName,
	domainId,
	domainBanner
}: IReportsTabContentProps): JSX.Element => {
	const [hasErrorOcurred, setHasErrorOcurred] = useState(false);
	const [dashboardCards, setDashboardCards] = useState([]);
	const { formatMessage } = useIntl();
	const { setIsLoading } = useLoaderContext();
	const { updateReportsDate } = useReportsContext();
	const [pagination, _] = useState<IPagination>({
		offset: 0,
		pageSize: PAGE_SIZE,
		count: 0
	});

	const {
		data: response,
		isLoading,
		refetch
	} = useGetReportsByDomain(domainId, PAGE_SIZE, pagination.offset, pagination, updateReportsDate, {
		enabled: !!domainId,
		onSuccess: (data) => {
			if (data) {
				const activeReports = data.data?.data?.filter(
					(report: IReport) => report.isEnabled === true
				);

				setDashboardCards(activeReports);
				tabGlobalReportSelectedTracking(domainName, activeReports.length);
				showNotify(formatMessage({ id: "DASHBOARD.TOAST_LOAD_SUCCESS" }), TypeToast.SUCCESS);
				setIsLoading(false);
				return;
			}

			tabGlobalReportSelectedTracking(domainName, 0);
		},
		onError: () => {
			setIsLoading(false);
			setHasErrorOcurred(true);
		}
	});

	const handleRefresh = () => {
		refetch();
	};

	return (
		<Container type="fluid" data-testid="report-tab">
			<Loader isLoadingProp={isLoading}>
				<Heading size="H2">About {domainName}</Heading>
				{domainBanner && (
					<DomainBanner
						imageBackground={domainBanner.imageBackground}
						domainLogo={domainBanner.domainLogo}
						text={domainBanner.text}
						documentationURL={domainBanner.documentationURL}
					/>
				)}
				<Heading size="H2">{domainName} Dashboards</Heading>
				<Paragraph>Quick access to all {domainName} reports available on the portal.</Paragraph>
				<WrapperStyled data-testid="dashboard-content">
					{hasErrorOcurred ? (
						<ReportErrorState handleRefresh={handleRefresh} />
					) : (
						<>
							{dashboardCards?.length > 0 ? (
								<>
									{dashboardCards?.map((card: IDashboardCard) => (
										<DashboardCard
											key={card.reportId}
											reportId={card.reportId}
											domainName={card.domain.domainName}
											reportTitle={card.reportTitle}
											status={card.status}
											ownerName={card.ownerName}
											ownerEmail={card.ownerEmail}
										/>
									))}
								</>
							) : (
								<ReportNoContentState />
							)}
						</>
					)}
				</WrapperStyled>
			</Loader>
		</Container>
	);
};

export default ReportsTabContent;
