import { Button, Paragraph } from "@hexa-ui/components";
import { DomainBannerStyled } from "./DomainBannerStyled";

export interface IDomainBannerProps {
	imageBackground: string;
	domainLogo?: JSX.Element;
	text?: string;
	documentationURL?: string;
}

export const DomainBanner = ({
	imageBackground,
	domainLogo,
	text,
	documentationURL
}: IDomainBannerProps): JSX.Element => {
	return (
		<DomainBannerStyled
			border="medium"
			elevated="medium"
			data-testid="hero-card"
			css={{ backgroundImage: `url(${imageBackground})` }}
		>
			<div className="description">
				{domainLogo}
				{text && <Paragraph>{text}</Paragraph>}
				{documentationURL && (
					<a href={documentationURL} target="_blank">
						<Button>Read more {">"}</Button>
					</a>
				)}
			</div>
		</DomainBannerStyled>
	);
};
