export enum EBeesDomains {
	PlatformSuccess = "Platform Success",
	Force = "Force",
	Care = "Care",
	Grow = "Grow",
	Rewards = "Rewards",
	DigitalComms = "Digital Comms",
	Customer = "Customer",
	Link = "Link",
	Deliver = "Deliver",
	Fintech = "Fintech",
	NPS = "NPS",
	GlobalOperations = "Global Operations",
}

export interface IBeesDomain {
	domainId: number;
	domainName: string;
	isEnabled: boolean;
	enabled?: boolean;
	workspaceId?: string;
}

export interface IBeesDomainsResponse {
	data: IBeesDomain[];
}

export interface IDomain {
	domainId: number;
	domainName: string;
	isEnabled: boolean;
}
