import { Card } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const DomainBannerStyled = styled(Card, {
	minHeight: "284px",
	padding: "48px",
	width: "100%",
	margin: "$4 0 $8",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	".description": {
		svg: {
			height: "28px",
			marginBottom: "$4"
		},
		p: {
			lineHeight: "$8",
			marginBottom: "$4",
			fontSize: "28px",
			fontFamily: "$heading",
			maxWidth: "60%"
		}
	},
	"@media (min-width: 1400px)": {
		".description": {
			marginLeft: "350px"
		}
	},
	"@media (max-width: 1400px)": {
		backgroundPosition: "center",
		".description": {
			p: {
				maxWidth: "100%"
			}
		}
	}
});
